import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

import {Observable} from 'rxjs/internal/Observable';
import {catchError, retryWhen} from 'rxjs/operators';
import {TeamItem} from '../models/team';
import {InvitationCollection, InvitationItem} from '../models/invitation';
import {UserCollection} from '../models/user';

@Injectable()
export class TeamService extends ApiService {
  get(id, options = {}): Observable<TeamItem> {
    const url = this.url + 'teams/' + id + ApiService.httpBuildQuery(options);
    return this.http.get<TeamItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('TeamService::get, url=' + url + ', id=' + id, <TeamItem>{},{
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  updateName(id, name): Observable<any> {
    const url = this.url + 'settings/teams/' + id + '/name';
    const data = {name: name};
    return this.http.put(url, data, {headers: this.getHeaders()}).pipe(catchError(this.handleError('TeamService::updateName, url=' + url + ', name=' + name, {})));
  }

  invite(id, email): Observable<any> {
    const url = this.url + 'settings/teams/' + id + '/invitations';
    return this.http.post(url, {email: email}, {headers: this.getHeaders()}).pipe(catchError(this.handleError('TeamService::invite, url=' + url + ', email=' + email, {})));
  }

  getInvitation(token): Observable<InvitationItem> {
    const url = this.url + 'invitations/' + token;
    return this.http.get<InvitationItem>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('TeamService::getInvitation, url=' + url, <InvitationItem>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  getInvitations(id): Observable<InvitationCollection> {
    const url = this.url + 'settings/teams/' + id + '/invitations';
    return this.http.get<InvitationCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('TeamService::filter, url=' + url + ', id=' + id, <InvitationCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }

  deleteInvitation(id): Observable<any> {
    const url = this.url + 'settings/invitations/' + id;
    return this.http.delete(url, {headers: this.getHeaders()}).pipe(catchError(this.handleError('TeamService::filter, url=' + url + ', id=' + id, [])));
  }

  deleteMember(team_id, member_id): Observable<any> {
    const url = this.url + 'settings/teams/' + team_id + '/members/' + member_id;
    return this.http.delete(url, {headers: this.getHeaders()}).pipe(
      catchError(
        this.handleError(
          'TeamService::deleteMember, url=' + url + ', team_id=' + team_id + ', member_id=' + member_id,
          [],
        ),
      ),
    );
  }

  acceptInvitation(id): Observable<any> {
    const url = this.url + 'settings/invitations/' + id + '/accept';
    return this.http.post(url, [], {headers: this.getHeaders()}).pipe(catchError(this.handleError('TeamService::acceptInvitation, url=' + url + ', id=' + id, [])));
  }

  rejectInvitation(id): Observable<any> {
    const url = this.url + 'settings/invitations/' + id + '/reject';
    return this.http.post(url, [], {headers: this.getHeaders()}).pipe(catchError(this.handleError('TeamService::rejectInvitation, url=' + url + ', id=' + id, [])));
  }

  members(id: number): Observable<UserCollection> {
    const url = this.url + 'teams/' + id + '/relationships/users';
    return this.http.get<UserCollection>(url, {headers: this.getHeaders()}).pipe(
      retryWhen(this.retryOnSystemFailure),
      catchError(this.handleError('TeamService::members, url=' + url + ', id=' + id, <UserCollection>{}, {
        url,
        method: 'GET',
        headers: this.getHeadersData(),
      })),
    );
  }
}
