import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AuthenticationService} from '../../../auth/authentication.service';
import {MatDialog} from '@angular/material';
import {ConfirmComponent} from '../../../dialogs/confirm/confirm.component';
import {Header} from '../../../models/content/header';
import {User} from '../../../models/user';
import {NavigationEnd, Router} from '@angular/router';
import { filter } from 'rxjs/operators';
import {$t} from 'codelyzer/angular/styles/chars';
import { environment } from 'src/environments/environment';

const MWU_LOGO_URL = '/assets/img/mwu-basic.jpg'
const MME_LOGO = '/assets/img/MME-Logo.png'

@Component({
  selector: 'vmc-header-agent',
  templateUrl: './header-agent.component.html',
  styleUrls: ['./header-agent.component.scss'],
})
export class HeaderAgentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public canManageBranches: boolean = false;
  @Input() public content: Header = new Header();
  @Input() public currentUser: User = new User();
  @Input() public home_link = '/listings';
  @Input() public isMenuOpened: boolean = false;
  @Input() public showBrochure = false;
  @Input() canManageClients = false;
  @Input() canManageClientContacts = false;
  @Input() canViewDashboard = true;
  @Input() canViewReportsPage = false;
  @Input() isClientUserHome = false;
  @Input() canManageClientUsers = false;

  @Output() onContactUs = new EventEmitter();
  @Output() onMenuToggle = new EventEmitter();

  @Input() public isCompanyConveyancer: boolean = false;
  @Input() public isIndividualConveyancer: boolean = false;
  @Input() public isIndividualConsumer: boolean = false;

  public openUserMenu = false;

  isProxyUser = false;
  isAdmin = false;

  clientRouteActive = false

  clientContactRouteActive = false

  isClientUser = false;

  // hideConsumerInvitation = false;

  canViewMyProperties = false;
  canViewMwuDashboard = false;
  canAddListing = false;
  canReportViewConnells = false;
  canAttachListing = false;
  canClientRead = false;
  canClientUserRead = false;
  canViewConsumerForm = false;
  canClientContactRead = false;
  canReadTask = false;

  urlMyTasks = environment.vmc_webapp2_url + 'tasks/list'

  constructor(
    private auth: AuthenticationService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.clientContactRouteActive = event.url.startsWith('/clients/contact-management') ||
        event.url.match(/\/clients\/[0-9]+\/contacts/) !== null
      this.clientRouteActive = event.url.startsWith('/clients') &&
        !this.clientContactRouteActive
    })
  }

  getUrlHome() {
    if (this.canViewMyProperties) {
      return '/listings';
    }
    return this.router.url;
  }

  ngOnInit(): void {
    document.body.addEventListener('mouseup', event => (this.openUserMenu = false), true);
    this.router.events.subscribe(event => {
      this.auth.can('view-my-properties').subscribe(can => {
        this.canViewMyProperties = can;
      });
      this.auth.can('view-mwu-dashboard').subscribe(can => (this.canViewMwuDashboard = can));
      this.auth.can('add-listing').subscribe(can => (this.canAddListing = can));
      this.auth.can('report-view-connells').subscribe(can => (this.canReportViewConnells = can));
      this.auth.can('attach-listing').subscribe(can => (this.canAttachListing = can));
      this.auth.can('client-read').subscribe(can => (this.canClientRead = can));
      this.auth.can('client-user-read').subscribe(can => (this.canClientUserRead = can));
      this.auth.can('view-consumer-form').subscribe(can => (this.canViewConsumerForm = can));
      this.auth.can('client-contact-read').subscribe(can => (this.canClientContactRead = can));
      this.auth.can('task-read').subscribe(can => (this.canReadTask = can));
    })
  }

  ngOnDestroy() {
    document.body.removeEventListener('mouseup', event => (this.openUserMenu = false), true);
  }

  toggleMenu() {
    this.onMenuToggle.emit();
  }

  logout() {
    const modal = this.dialog.open(ConfirmComponent, {data: {question: 'Are you sure you want to log out?'}});
    modal.afterClosed().subscribe(result => {
      if (result) {
        this.auth.logout();
      }
    });
  }

  toggleUserMenu() {
    this.openUserMenu = !this.openUserMenu;
  }

  showContact() {
    this.onContactUs.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isProxyUser = this.currentUser.attributes.type.toLowerCase() == 'proxy';
    this.isClientUser = this.currentUser.attributes.type.toLowerCase() == 'client_user';
    this.isAdmin = this.currentUser.attributes.type.toLowerCase() == 'admin';
  }

  get headerLogo() {
    if (this.isClientUserHome && this.isClientUser) {
      const isMME = Boolean(this.auth.teams.find(team => team.attributes.branch_id == -1040));
      if (isMME) {
        return MME_LOGO;
      }
      return MWU_LOGO_URL
    }
    return this.content.headerLogo
  }

  reloadPage(supportLink: HTMLElement) {
    supportLink.blur();
    setTimeout(() => window.location.reload(), 100);
  }

  redirectToMyTasks() {
    window.location.href = this.urlMyTasks;
  }

  redirectToNewConsumerForm() {
    window.location.href = environment.vmc_webapp2_url + 'consumer/create'
  }

  redirectToNewProfileView() {
    window.location.href = environment.vmc_webapp2_url + 'profile'
  }

  redirectToNewListings() {
    window.location.href = environment.vmc_webapp2_url + 'listings'
  }

  redirectToNewReports() {
    window.location.href = environment.vmc_webapp2_url + 'reports'
  }

  redirectToCompanyConveyancerAttachListing() {
    window.location.href = environment.vmc_webapp2_url + 'attach-listing'
  }

  redirectToProxyAttachListing() {
    window.location.href = environment.vmc_webapp2_url + 'proxy/attach-listing'
  }
}
