// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  env: 'demo',// or staging or production
  name: 'dev',
  intercom_api_key: '',
  intercom_app_id: '',
  intercom_identity_verification: '',
  vmc_marketing_site: 'https://viewmychain.com/',
  vmc_root_url: 'https://app.demo.viewmychain.com',
  vmc_api_url: 'https://admin.demo.viewmychain.com/api/v1/',
  vmc_webapp2_url: 'https://app2.demo.viewmychain.com/',
  share_cookies_domain: '.demo.viewmychain.com',
  legacy_url: '',
  hmr: true,
  gtm_container_id: '',
  gtm_auth: '',
  gtm_preview: '',
  sentry_hook: '',
  consumer_role_id: 4,
  docs_menu_id: '27aDHZKfluysyMeg4Q2u8u',
  build_version: '{BUILD_VERSION}',
  recaptcha_site_key: '6LcBtVwUAAAAAG-uB21HpnNtAfSEeGNt6tUQTa7H',
  consumer_sign_up_content_id: '4d6XUnuRg4KyYgiUguO2Ac',
  pusher_app_key: '',
  pusher_app_cluster: 'eu',
  pusher_app_encrypted: false,
  header_id: '5lAOg9WAmsOs8Gg6qoSYGe',
  footer_id: '14RitKkRh6MgmukQ644Emc',
  mwu_logo_url: '/assets/img/mwu-basic.jpg',
}
